











































































import { Component, Mixins } from 'vue-property-decorator';
import TableTreeComponent from '@/mixins/table-tree-component';
import TableDialogFormComponent from '@/mixins/table-dialog-form-component';
import { PermissionEntityModel, PermissionQueryModel } from '@/entity-model/permission-entity';
import PermissionService from '@/service/permission';
import MENUS from '@/json/menu.json';
import ImportPermissionDialog from './import-permission-dialog.vue';

@Component({
    components: {
        'import-permission-dialog': ImportPermissionDialog
    }
})
export default class PermissionListComponent extends Mixins(TableDialogFormComponent, TableTreeComponent) {
    PermissionEntityModel = PermissionEntityModel;
    permissionGroupList: Array<any> = null;
    selectedPermissionGroupId: Array<string> = null;
    selectedPermissionGroup: Object = {};
    syncLoading: boolean = false;

    created() {
        this.initTable({
            service: PermissionService,
            queryModel: new PermissionQueryModel(),
            tableColumns: PermissionEntityModel.getTableColumns()
        });
        PermissionService.getAllPermissionGroup().then(res => {
            this.permissionGroupList = res;
            if (this.permissionGroupList && this.permissionGroupList.length > 0) {
                this.selectedPermissionGroup = this.permissionGroupList[0];
                this.selectedPermissionGroupId = [this.permissionGroupList[0].id];
                (this.queryModel as PermissionQueryModel).group = this.permissionGroupList[0].id;
            }
        });
    }

    addPermissionClick() {
        const newPermissionEntity = new PermissionEntityModel();
        newPermissionEntity.groupModel = this.selectedPermissionGroup;
        this.addClick(newPermissionEntity);
    }
    editPermissionClick(record: PermissionEntityModel) {
        record.groupModel = this.selectedPermissionGroup;
        this.editClick(record, true);
    }

    addChildClick(record: PermissionEntityModel) {
        const childPermissionEntity = new PermissionEntityModel();
        childPermissionEntity.parent = record;
        childPermissionEntity.type = record.type;
        childPermissionEntity.groupModel = this.selectedPermissionGroup;
        this.addClick(childPermissionEntity);
    }

    menuClick(permissionGroup: any) {
        this.selectedPermissionGroup = permissionGroup;
        this.selectedPermissionGroupId = [permissionGroup.id];
        (this.queryModel as PermissionQueryModel).group = permissionGroup.id;
    }

    importPermissionClick() {
        const groupId = _.get(this.selectedPermissionGroup, 'id');
        (this.$refs.importPermissionDialog as ImportPermissionDialog).dialogOpen(groupId);
    }

    exportPermissionClick() {
        const groupId = _.get(this.selectedPermissionGroup, 'id');
        return PermissionService.exportPermission(groupId).then(() => {
            this.showMessageSuccess('导出成功');
        });
    }

    async syncPermission() {
        if (_.isEmpty(this.listData)) {
            return;
        }
        this.syncLoading = true;
        try {
            // 获取每个权限的资源
            for (let i = 0; i < this.listData.length; i++) {
                this.listData[i].resources = await PermissionService.getResources(this.listData[i].id);
                if (!_.isEmpty(this.listData[i].children)) {
                    for (let j = 0; j < this.listData[i].children.length; j++) {
                        this.listData[i].children[j].resources = await PermissionService.getResources(this.listData[i].children[j].id);
                        // if (!_.isEmpty(this.listData[i].children[j].children)) {
                        //     for (let k = 0; k < this.listData[i].children[j].children.length; k++) {
                        //         this.listData[i].children[j].children[k].resources = await PermissionService.getResources(this.listData[i].children[j].children[k].id);
                        //     }
                        // }
                    }
                }
            }
            const groupType = _.get(this.selectedPermissionGroup, 'type');
            const currentGroupMenus = _.filter(_.cloneDeep(MENUS), item => item.permission && item.permission?.indexOf(groupType) > -1);
            if (currentGroupMenus && currentGroupMenus.length > 0) {
                for (let i = 0; i < currentGroupMenus.length; i++) {
                    console.group(currentGroupMenus[i].name);
                    const findItem = _.find(this.listData, item => _.map(_.filter(item.resources, res => res.httpMethod === 'GET'), resource => resource.url).indexOf(currentGroupMenus[i].route) > -1);
                    if (findItem) {
                        if (findItem.name !== currentGroupMenus[i].name) {
                            console.warn(`update ${findItem.name}, ${JSON.stringify(findItem.resources)} -> ${currentGroupMenus[i].name} [${currentGroupMenus[i].route}]`);
                            // debugger;
                            findItem.name = currentGroupMenus[i].name;
                            await PermissionService.update(findItem);
                        }
                        // level 2
                        const sub2CurrentGroupMenus: any = _.filter(currentGroupMenus[i].subMenus, item => item.permission && item.permission?.indexOf(groupType) > -1);
                        if (!_.isEmpty(sub2CurrentGroupMenus)) {
                            for (let j = 0; j < sub2CurrentGroupMenus.length; j++) {
                                console.group(sub2CurrentGroupMenus[j].name);
                                const findSub2Item = _.find(findItem.children, item => _.map(_.filter(item.resources, res => res.httpMethod === 'GET'), resource => resource.url).indexOf(sub2CurrentGroupMenus[j].route) > -1);
                                if (findSub2Item) {
                                    if (findSub2Item.name !== sub2CurrentGroupMenus[j].name) {
                                        console.warn(`update ${findSub2Item.name}, ${JSON.stringify(findSub2Item.resources)} -> ${sub2CurrentGroupMenus[j].name} [${sub2CurrentGroupMenus[j].route}]`);
                                        findSub2Item.name = sub2CurrentGroupMenus[j].name;
                                        await PermissionService.update(findSub2Item);
                                    }

                                    // // level 3
                                    // const sub3CurrentGroupMenus: any = _.filter(sub2CurrentGroupMenus[j].subMenus, item => item.permission && item.permission?.indexOf(groupType) > -1);
                                    // if (!_.isEmpty(sub3CurrentGroupMenus)) {
                                    //     for (let k = 0; k < sub3CurrentGroupMenus.length; k++) {
                                    //         const findSub3Item = _.find(findSub2Item.children, item => _.map(_.filter(item.resources, res => res.httpMethod === 'GET'), resource => resource.url).indexOf(sub3CurrentGroupMenus[k].route) > -1);
                                    //         if (findSub3Item) {
                                    //             if (findSub3Item.name !== sub3CurrentGroupMenus[k].name) {
                                    //                 console.log(`update ${findSub3Item.name}, ${findSub3Item.resources} -> ${sub3CurrentGroupMenus[k].name}/${sub3CurrentGroupMenus[k].route}`);
                                    //             }
                                    //         } else {
                                    //             console.log(`add ${sub3CurrentGroupMenus[k].name}/${sub3CurrentGroupMenus[k].route}`);
                                    //         }
                                    //     }
                                    // }
                                    // // end level 3
                                } else {
                                    console.warn(`add ${sub2CurrentGroupMenus[j].name}/${sub2CurrentGroupMenus[j].route}`);
                                    const addPermissioModel = new PermissionEntityModel();
                                    addPermissioModel.name = sub2CurrentGroupMenus[j].name;
                                    addPermissioModel.resources = [{ httpMethod: 'GET', url: sub2CurrentGroupMenus[j].route }];
                                    addPermissioModel.parentId = findItem.id;
                                    addPermissioModel.groupModel = this.selectedPermissionGroup;
                                    addPermissioModel.remark = '批量同步';
                                    await PermissionService.create(addPermissioModel);
                                }
                                console.groupEnd();
                            }
                        }
                        // end level 2
                    } else {
                        const addPermissioModel = new PermissionEntityModel();
                        addPermissioModel.name = currentGroupMenus[i].name;
                        addPermissioModel.resources = [{ httpMethod: 'GET', url: currentGroupMenus[i].route }];
                        addPermissioModel.groupModel = this.selectedPermissionGroup;
                        addPermissioModel.remark = '批量同步';
                        const parentPermissionModel = await PermissionService.create(addPermissioModel);
                        console.warn(`add ${currentGroupMenus[i].name} [${currentGroupMenus[i].route}]`);
                        const sub2CurrentGroupMenus: any = _.filter(currentGroupMenus[i].subMenus, item => item.permission && item.permission?.indexOf(groupType) > -1);
                        if (!_.isEmpty(sub2CurrentGroupMenus)) {
                            for (let j = 0; j < sub2CurrentGroupMenus.length; j++) {
                                console.warn(`add ${sub2CurrentGroupMenus[j].name} [${sub2CurrentGroupMenus[j].route}]`);
                                const addPermissioModel = new PermissionEntityModel();
                                addPermissioModel.name = sub2CurrentGroupMenus[j].name;
                                addPermissioModel.resources = [{ httpMethod: 'GET', url: sub2CurrentGroupMenus[j].route }];
                                addPermissioModel.parentId = _.get(parentPermissionModel, 'id');
                                addPermissioModel.groupModel = this.selectedPermissionGroup;
                                addPermissioModel.remark = '批量同步';
                                await PermissionService.create(addPermissioModel);
                            }
                        }
                    }
                    console.groupEnd();
                }
            }
            this.syncLoading = false;
            this.getList();
        } catch (e) {
            this.showMessageError(e);
            this.syncLoading = false;
            this.getList();
        }
    }

    // private async comparePermission(source, target) {
    //     if (source) {
    //         if (source.name !== target.name) {
    //             console.log(`update ${source.name}, ${source.resources} -> ${target.name}/${target.route}`);
    //         }
    //     } else {
    //         console.log(`add ${target.name}/${target.route}`);
    //     }
    // }
}

