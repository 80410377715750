import { BaseEntityModel } from '@/model/base-model';
import { FormControl, FormControlType, FormControlTextModel } from '@/model/form-control';
import { QueryModel } from '@/model/query-model';
import { QueryControl, QueryControlType } from '@/model/query-control';
import { PermissionType } from '@/model/enum';

export class PermissionEntityModel extends BaseEntityModel {
    @FormControl({
        label: '名称',
        type: FormControlType.TEXT,
        required: true
    } as FormControlTextModel)
    name: string = undefined;

    @FormControl({
        label: '资源',
        type: FormControlType.RESOURCE_URL
    } as FormControlTextModel)
    resources: Array<{httpMethod: string, url: string}> = [{ httpMethod: 'GET', url: '' }];

    @FormControl({
        label: '描述',
        type: FormControlType.TEXT_AREA
    } as FormControlTextModel)
    remark: string = undefined;

    children: Array<PermissionEntityModel> = undefined;
    select: boolean = false;
    type: PermissionType = undefined;
    level: number = undefined;
    parent: PermissionEntityModel;
    groupModel: any;
    groupId: string = undefined;
    mediaType: string = undefined;
    modelStatus: string = undefined;
    parentId: string = undefined;

    get AllSelect(): boolean {
        if (this.children && this.children.length > 0) {
            this.select = _.filter(this.children, item => item.select).length === this.children.length;
        }
        return this.select;
    }
    set AllSelect(val: boolean) {
        _.forEach(this.children, item => {
            item.select = val;
            item.AllSelect = val;
        });
        this.select = val;
    }
    get Indeterminate() {
        if (this.children && this.children.length > 0) {
            const selectLength = _.filter(this.children, item => item.select).length;
            const hasIndeterminate = _.filter(this.children, item => item.Indeterminate).length > 0;
            return hasIndeterminate || (selectLength > 0 && selectLength < this.children.length);
        }
        return false;
    }

    get HasChildren() {
        return this.children && this.children.length > 0;
    }

    setSelect(selectedPermissions: Array<string>) {
        const selectedPermission = _.find(selectedPermissions, item => item === this.id);
        if (this.level === 1 || selectedPermission) {
            this.select = true;
            _.forEach(this.children, item => {
                item.setSelect(selectedPermissions);
            });
        } else {
            this.select = false;
        }
    }

    static getTableColumns() {
        return [
            {
                title: '名称',
                dataIndex: 'name',
                scopedSlots: { customRender: 'name' }
            },
            {
                title: '描述',
                dataIndex: 'remark'
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' }
            }
        ];
    }

    toModel(json): any {
        super.toModel(json);
        if (!this.resources || this.resources.length === 0) {
            this.resources = [{ httpMethod: 'GET', url: '' }];
        }
        if (_.get(json, 'children') || _.get(json, 'permissions')) {
            this.children = _.map(_.get(json, 'children') || _.get(json, 'permissions'), item => {
                const child: PermissionEntityModel = new PermissionEntityModel().toModel(Object.assign({ level: this.level + 1 }, item));
                child.parent = this;
                return child;
            });
        }
        return this;
    }

    toService() {
        const data: any = {
            id: this.id,
            name: this.name,
            type: this.type || _.get(this.groupModel, 'type'),
            parentId: this.parentId || _.get(this.parent, 'id'),
            groupId: this.groupId || _.get(this.groupModel, 'id'),
            mediaType: this.mediaType || _.get(this.groupModel, 'mediaType'),
            remark: this.remark,
            resources: this.resources
        };
        // if (this.HasChildren) {
        //     data.children = _.map(_.filter(this.children, item => item.select || item.Indeterminate), (item: any) => item.toService());
        // }
        return data;
    }

    toRoleService() {
        const data: any = {
            id: this.id,
            name: this.name,
            type: this.type
        };
        if (this.HasChildren) {
            data.children = _.map(_.filter(this.children, item => item.select || item.Indeterminate), (item: any) => item.toRoleService());
        }
        return data;
    }
}

export class PermissionQueryModel extends QueryModel {
    @QueryControl({
        label: '名称',
        type: QueryControlType.TEXT
    })
    name: string = undefined;

    group: string = undefined;

    toService() {
        return {
            params: this.group
        };
    }
}
