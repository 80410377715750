

















import { Component } from 'vue-property-decorator';
import FormDialogComponent from '@/mixins/form-dialog-component';
import { ICRUDQ } from '@/model/interface';
import { ViewModeType } from '@/model/enum';
import PermissionService from '@/service/permission';
import { FormControlType } from '@/model/form-control';

@Component
export default class ImportPermissionDialog extends FormDialogComponent<any> {
    groupId: string;
    dialogOpen(groupId: string): void {
        this.groupId = groupId;
        this.dialogVisible = true;
        this.viewMode = ViewModeType.NEW;
        this.dialogTitle = '批量导入权限';
        this.$nextTick(() => {
            if (this.jtlForm) {
                // this.jtlForm.initForm(importModel, viewMode);
                this.jtlForm.initFormByControls([{
                    key: 'file',
                    label: '权限列表文件（json）',
                    type: FormControlType.UPLOAD,
                    accept: '.json',
                    maxFileNumber: 1,
                    notUploadOss: true,
                    required: true
                }] as any);
            }
        });
    }

    dialogOK(): any {
        if (!this.groupId) {
            this.showMessageWarning('请先选择权限分组');
            return;
        }
        if (!this.jtlForm) {
            return;
        }
        this.startFullScreenLoading('正在导入，请耐心等待......');
        return new Promise((resolve, reject) => {
            this.jtlForm.submitForm().then((ret) => {
                PermissionService.importPermission(this.groupId, _.get(this.jtlForm, 'formModel.file')).then(res => {
                    this.showMessageSuccess('批量导入权限成功');
                    this.dialogClose();
                    this.$emit('dialogOK');
                    resolve(null);
                }).catch(err => {
                    reject();
                    throw err;
                });
            }).catch(err => {
                reject();
                throw err;
            });
        }).finally(() => {
            this.stopFullScreenLoading();
        });
    }
}
